import { Loader } from '@googlemaps/js-api-loader';
import { GoogleSuggestion } from '@common/types';

const G_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const G_CITY = ['locality', 'administrative_area_level_1', 'administrative_area_level_2'];
const G_ROUTE = ['route', 'sublocality', 'sublocality_level_1', 'administrative_area_level_2'];

type AddressComponent = google.maps.GeocoderAddressComponent;
type LatLngLiteral = google.maps.LatLngLiteral;

const getFallbackOption = (
  city: string,
  country: string,
  cords: LatLngLiteral,
): GoogleSuggestion => ({
  value: `${city}, ${country}`,
  dataset: {
    isFacilityLocation: true,
    location: `${city}, ${country}`,
    town: city,
    ...cords,
  },
});

const sortByPriority = (a: AddressComponent, b: AddressComponent, priorities: string[]) =>
  priorities.findIndex((type) => a.types.includes(type)) -
  priorities.findIndex((type) => b.types.includes(type));

const mapResponseToSuggestion = (result: google.maps.places.PlaceResult) => {
  const location = result.name
    ? `${result.name} - ${result.formatted_address}`
    : result.formatted_address!;
  const town =
    (result.plus_code?.compound_code?.split(' ')[1] ?? '').replace(/,$/, '') ||
    result.formatted_address;

  return {
    value: location,
    dataset: {
      lat: result.geometry?.location?.lat() ?? 0,
      lng: result.geometry?.location?.lng() ?? 0,
      location,
      town,
    },
  };
};

const processPlaceDetails = async ({
  city,
  coords,
  country,
  place,
}: {
  city: string;
  coords: LatLngLiteral;
  country: string;
  place: google.maps.places.PlaceResult;
}): Promise<GoogleSuggestion> => {
  const address = place.address_components || [];
  const hashPattern = /\b\w*\+\w*/;
  const placeName = place.name?.replace(hashPattern, '').trim();

  address.sort((a, b) => sortByPriority(a, b, G_CITY));
  const town =
    address.find((i) => G_CITY.some((j) => i.types.includes(j)))?.long_name ||
    place.formatted_address;

  address.sort((a, b) => sortByPriority(a, b, G_ROUTE));
  const route = address.find((i) => G_ROUTE.some((j) => i.types.includes(j)))?.short_name;

  let location = placeName
    ? `${placeName}${route ? ` - ${route}` : ''}, ${town}`
    : `${route ? `${route}, ` : ''}${town}`;

  const hashMatchInName = place.name?.match(hashPattern);
  const hashMatchInAddress = place.formatted_address?.match(hashPattern);

  if (hashMatchInAddress) {
    const hash = hashMatchInAddress[0];
    location = location.replace(hash, '').trim();
    location = `${location}, ${hash}`;

    const formattedAddressWords = place.formatted_address?.split(' ') || [];
    const isFacilityLocation =
      hashMatchInAddress && hashMatchInName && formattedAddressWords.length <= 3;

    if (place.formatted_address === hash) {
      location = `${city}, ${country}, ${hash}`;
    }

    return {
      value: location,
      dataset: {
        ...coords,
        location,
        town,
        ...(isFacilityLocation ? { isFacilityLocation: true } : {}),
      },
    };
  }

  return {
    value: location,
    dataset: { ...coords, location, town },
  };
};

const fetchPlaceDetails = async ({
  city,
  coords,
  country,
  debuggingGoogleMap,
  language,
  placeId,
  onChange,
}: {
  city: string;
  coords: LatLngLiteral;
  country: string;
  debuggingGoogleMap?: boolean;
  language: string;
  placeId: string;
  onChange?: (e: GoogleSuggestion | string) => void;
}) => {
  const loader = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
  const { PlacesService } = await loader.importLibrary('places');
  const placesService = new PlacesService(document.createElement('div'));
  const place = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
    placesService.getDetails(
      {
        fields: ['address_components', 'formatted_address', 'geometry', 'name', 'place_id'],
        language,
        placeId,
      },
      (placeResult, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && placeResult) {
          resolve(placeResult);
        } else {
          reject(new Error('Failed to fetch place details'));
        }
      },
    );
  });

  const details = await processPlaceDetails({ city, coords, country, place });
  onChange?.(details);

  if (debuggingGoogleMap) {
    console.log('google place details', place); // TODO: temporary log for testing
    console.log('payload', details); // TODO: temporary log for testing
  }
};

export const fetchLocation = async ({
  city,
  coords,
  country,
  debuggingGoogleMap = false,
  language,
  onChange,
}: {
  city: string;
  coords: LatLngLiteral;
  country: string;
  debuggingGoogleMap?: boolean;
  language: string;
  onChange?: (e: GoogleSuggestion | string) => void;
}): Promise<void> => {
  const fallbackOption = getFallbackOption(city, country, coords);

  try {
    const loader = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
    await loader.importLibrary('geocoding');
    const geocoder = new google.maps.Geocoder();

    const res = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      geocoder.geocode({ language, location: coords }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results) {
          resolve(results);
        } else {
          reject(new Error('Failed to fetch location details'));
        }
      });
    });

    if (res.length) {
      fetchPlaceDetails({
        city,
        coords,
        country,
        debuggingGoogleMap,
        language,
        placeId: res[0]?.place_id,
        onChange,
      });
    } else {
      onChange?.(fallbackOption);
    }
  } catch (error) {
    onChange?.(fallbackOption);
  }
};

export const fetchSuggestions = async ({
  query,
  language,
  region,
}: {
  query: string;
  language: string;
  region: string;
}): Promise<GoogleSuggestion[]> => {
  const loader = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
  const { PlacesService } = await loader.importLibrary('places');
  const placesService = new PlacesService(document.createElement('div'));

  const res = await new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
    placesService.textSearch({ query, language, region }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        resolve(results);
      } else {
        reject(new Error('Failed to fetch text search results'));
      }
    });
  });

  return [
    { value: 'Select the location on Google map' },
    ...(res.map(mapResponseToSuggestion) ?? []),
  ];
};

export const fetchDistanceMatrix = async ({
  origins,
  destinations,
  debuggingGoogleMap,
}: {
  origins: LatLngLiteral[];
  destinations: LatLngLiteral[];
  debuggingGoogleMap?: boolean;
}): Promise<{ distance: number; duration: number } | null> => {
  const loader = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
  await loader.importLibrary('routes');
  const service = new google.maps.DistanceMatrixService();

  try {
    const response = await new Promise<google.maps.DistanceMatrixResponse>((resolve, reject) => {
      service.getDistanceMatrix(
        { origins, destinations, travelMode: google.maps.TravelMode.DRIVING },
        (res, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK && res) {
            resolve(res);
          } else {
            reject(new Error('Failed to fetch distance matrix'));
          }
        },
      );
    });

    const result = response.rows[0].elements[0];
    const distance = parseFloat((result.distance?.text ?? '').replace(/[^\d.]/g, '')) || 0;
    const formattedDistance = distance >= 1 ? distance.toFixed(0) : (distance * 1000).toFixed(0);

    if (debuggingGoogleMap) {
      console.log('google distance matrix', result); // TODO: temporary log for testing
    }

    // The distance is returned in kilometers and the duration in seconds.
    return {
      distance: Number(formattedDistance),
      duration: result.duration?.value ?? 0,
    };
  } catch (error) {
    return null;
  }
};
