/* istanbul ignore file */
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DriveStatus, BDDrive, PlDriver, PlVehicle } from '@common/interfaces';
import { Button } from '@components';
import {
  CommentField,
  DriverField,
  DropOffDateField,
  DropOffTimeField,
  PickupDateField,
  PickupTimeField,
  VehicleField,
} from './fields';
import '../BookingPanel.styles.scss';

interface EditDriveFormItemProps {
  drive: BDDrive;
  drivers: PlDriver[];
  vehicles: PlVehicle[];
  onCancel: (driveId: string) => void;
  onChange?: () => void;
}

const EditDriveFormItem: FC<EditDriveFormItemProps> = ({
  drive,
  drivers,
  vehicles,
  onCancel,
  onChange,
}) => {
  const { t } = useTranslation();
  const isDriveDisabled = drive.state !== DriveStatus.NotStarted;

  const [isConfirm, setConfirm] = useState(false);

  const onFieldChange = () => {
    onChange?.();
  };

  return (
    <section className="form-edit-drive-item">
      <div className="form-drive">
        <div className="form-drive-row">
          <VehicleField
            disabled={isDriveDisabled}
            leg={drive.driveNumber}
            vehicle={drive.vehicleId}
            vehicles={vehicles}
            onChange={onFieldChange}
          />
          <DriverField
            disabled={isDriveDisabled}
            driver={drive.driverId}
            drivers={drivers}
            leg={drive.driveNumber}
            vehicles={vehicles}
            onChange={onFieldChange}
          />
          <PickupDateField
            disabled={isDriveDisabled}
            leg={drive.driveNumber}
            value={drive.pickupDate}
            onChange={onFieldChange}
          />
          <PickupTimeField
            disabled={isDriveDisabled}
            leg={drive.driveNumber}
            value={drive.pickupTime}
            onChange={onFieldChange}
          />
          <DropOffDateField
            disabled={isDriveDisabled}
            leg={drive.driveNumber}
            value={drive.dropoffDate}
            onChange={onFieldChange}
          />
          <DropOffTimeField
            disabled={isDriveDisabled}
            isEdit
            leg={drive.driveNumber}
            value={drive.dropoffTime}
            onChange={onFieldChange}
          />
        </div>
        <div className="form-drive-row">
          <CommentField
            label="Comment for Passenger"
            name={`${drive.driveNumber}.commentToPax`}
            placeholder="(optional)"
            value={drive.commentToPax}
            onChange={onFieldChange}
          />

          <CommentField
            label="Comment for Driver"
            name={`${drive.driveNumber}.commentToDriver`}
            placeholder="(optional)"
            value={drive.commentToDriver}
            onChange={onFieldChange}
          />
        </div>
      </div>

      <div className="form-drive-ctrl">
        {isConfirm ? (
          <>
            <p>{t('planner.msgCancelDriveConfirm')}</p>
            <div className="form-drive-buttons">
              <Button
                className="btn"
                text={t('common.yes')}
                type="button"
                variant="submit"
                onClick={() => {
                  onCancel(drive?.id);
                  setConfirm(false);
                }}
              />
              <Button
                className="btn"
                text={t('common.no')}
                variant="danger"
                onClick={() => setConfirm(false)}
              />
            </div>
          </>
        ) : null}

        {drive.state === DriveStatus.NotStarted && !isConfirm ? (
          <div className="form-drive-buttons">
            <Button
              className="btn"
              disabled={!drive.isActive}
              text={t('common.btnCancel')}
              variant="danger"
              onClick={() => setConfirm(true)}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default EditDriveFormItem;
